/* tslint:disable */
/* eslint-disable */
/**
 * osakaserver
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessKey
 */
export interface AccessKey {
    /**
     * 
     * @type {string}
     * @memberof AccessKey
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface AiContextMessage
 */
export interface AiContextMessage {
    /**
     * 
     * @type {string}
     * @memberof AiContextMessage
     */
    'role': AiContextMessageRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AiContextMessage
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof AiContextMessage
     */
    'hideUi': boolean;
    /**
     * 
     * @type {string}
     * @memberof AiContextMessage
     */
    'timestamp': string;
}

export const AiContextMessageRoleEnum = {
    User: 'user',
    Assistant: 'assistant'
} as const;

export type AiContextMessageRoleEnum = typeof AiContextMessageRoleEnum[keyof typeof AiContextMessageRoleEnum];

/**
 * 
 * @export
 * @interface AiEnrichmentDetails
 */
export interface AiEnrichmentDetails {
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentDetails
     */
    'book': string;
    /**
     * 
     * @type {number}
     * @memberof AiEnrichmentDetails
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AiEnrichmentDetails
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentDetails
     */
    'type': AiEnrichmentDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentDetails
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentDetails
     */
    'aimodel'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentDetails
     */
    'initialQuery': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiEnrichmentDetails
     */
    'predefinedQueries'?: Array<string>;
}

export const AiEnrichmentDetailsTypeEnum = {
    Ai: 'ai',
    Url: 'url'
} as const;

export type AiEnrichmentDetailsTypeEnum = typeof AiEnrichmentDetailsTypeEnum[keyof typeof AiEnrichmentDetailsTypeEnum];

/**
 * 
 * @export
 * @interface AiEnrichmentRequest
 */
export interface AiEnrichmentRequest {
    /**
     * 
     * @type {number}
     * @memberof AiEnrichmentRequest
     */
    'prededefinedQueryIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentRequest
     */
    'customQuery'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiEnrichmentRequest
     */
    'aiContext'?: string;
}
/**
 * 
 * @export
 * @interface AiModel
 */
export interface AiModel {
    /**
     * 
     * @type {string}
     * @memberof AiModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AiModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AiModel
     */
    'maxTokens'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AiModel
     */
    'capabilities'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AiModel
     */
    'default'?: boolean;
}
/**
 * 
 * @export
 * @interface AiProvider
 */
export interface AiProvider {
    /**
     * 
     * @type {string}
     * @memberof AiProvider
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AiProvider
     */
    'name': string;
    /**
     * 
     * @type {Array<AiModel>}
     * @memberof AiProvider
     */
    'models': Array<AiModel>;
}
/**
 * 
 * @export
 * @interface BookDetails
 */
export interface BookDetails {
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'authors': string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface DownloadBookContents404Response
 */
export interface DownloadBookContents404Response {
    /**
     * 
     * @type {number}
     * @memberof DownloadBookContents404Response
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof DownloadBookContents404Response
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface EnrichmentDetails
 */
export interface EnrichmentDetails {
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'book': string;
    /**
     * 
     * @type {number}
     * @memberof EnrichmentDetails
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof EnrichmentDetails
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'type': EnrichmentDetailsTypeEnum;
}

export const EnrichmentDetailsTypeEnum = {
    Ai: 'ai',
    Url: 'url'
} as const;

export type EnrichmentDetailsTypeEnum = typeof EnrichmentDetailsTypeEnum[keyof typeof EnrichmentDetailsTypeEnum];

/**
 * 
 * @export
 * @interface GenerateQueries200Response
 */
export interface GenerateQueries200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateQueries200Response
     */
    'followupQueries': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateQueries200Response
     */
    'initialQuery': string;
}
/**
 * 
 * @export
 * @interface GenerateQueriesRequest
 */
export interface GenerateQueriesRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateQueriesRequest
     */
    'contents': string;
    /**
     * 
     * @type {number}
     * @memberof GenerateQueriesRequest
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof GenerateQueriesRequest
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateQueriesRequest
     */
    'aimodel'?: string;
}
/**
 * 
 * @export
 * @interface GetAiProvidersResponse
 */
export interface GetAiProvidersResponse {
    /**
     * 
     * @type {Array<AiProvider>}
     * @memberof GetAiProvidersResponse
     */
    'providers': Array<AiProvider>;
}
/**
 * 
 * @export
 * @interface GetEnrichmentDetails200Response
 */
export interface GetEnrichmentDetails200Response {
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'book': string;
    /**
     * 
     * @type {number}
     * @memberof GetEnrichmentDetails200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetEnrichmentDetails200Response
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'type': GetEnrichmentDetails200ResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'aimodel'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'initialQuery': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEnrichmentDetails200Response
     */
    'predefinedQueries'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetEnrichmentDetails200Response
     */
    'url': string;
}

export const GetEnrichmentDetails200ResponseTypeEnum = {
    Ai: 'ai',
    Url: 'url'
} as const;

export type GetEnrichmentDetails200ResponseTypeEnum = typeof GetEnrichmentDetails200ResponseTypeEnum[keyof typeof GetEnrichmentDetails200ResponseTypeEnum];

/**
 * 
 * @export
 * @interface GetReaderAiProviderDetails200Response
 */
export interface GetReaderAiProviderDetails200Response {
    /**
     * 
     * @type {string}
     * @memberof GetReaderAiProviderDetails200Response
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof GetReaderAiProviderDetails200Response
     */
    'provider': string;
}
/**
 * 
 * @export
 * @interface HistoryDetails
 */
export interface HistoryDetails {
    /**
     * 
     * @type {string}
     * @memberof HistoryDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HistoryDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryDetails
     */
    'bookId': string;
    /**
     * 
     * @type {string}
     * @memberof HistoryDetails
     */
    'bookTitle': string;
    /**
     * 
     * @type {number}
     * @memberof HistoryDetails
     */
    'page': number;
    /**
     * 
     * @type {string}
     * @memberof HistoryDetails
     */
    'lastMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryDetails
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryDetails
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface LLMRequest
 */
export interface LLMRequest {
    /**
     * 
     * @type {string}
     * @memberof LLMRequest
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof LLMRequest
     */
    'model'?: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof LLMRequest
     */
    'messages': Array<Message>;
    /**
     * 
     * @type {number}
     * @memberof LLMRequest
     */
    'maxTokens'?: number;
    /**
     * 
     * @type {number}
     * @memberof LLMRequest
     */
    'temperature'?: number;
}
/**
 * 
 * @export
 * @interface LLMResponse
 */
export interface LLMResponse {
    /**
     * 
     * @type {string}
     * @memberof LLMResponse
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof LLMResponse
     */
    'model': string;
    /**
     * 
     * @type {string}
     * @memberof LLMResponse
     */
    'provider': string;
    /**
     * 
     * @type {LLMResponseUsage}
     * @memberof LLMResponse
     */
    'usage'?: LLMResponseUsage;
}
/**
 * 
 * @export
 * @interface LLMResponseUsage
 */
export interface LLMResponseUsage {
    /**
     * 
     * @type {number}
     * @memberof LLMResponseUsage
     */
    'totalTokens': number;
    /**
     * 
     * @type {number}
     * @memberof LLMResponseUsage
     */
    'completionTokens': number;
    /**
     * 
     * @type {number}
     * @memberof LLMResponseUsage
     */
    'promptTokens': number;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'role': MessageRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'name'?: string;
}

export const MessageRoleEnum = {
    System: 'system',
    User: 'user',
    Assistant: 'assistant'
} as const;

export type MessageRoleEnum = typeof MessageRoleEnum[keyof typeof MessageRoleEnum];

/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'stack'?: string;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialAiEnrichmentDetails
 */
export interface PartialAiEnrichmentDetails {
    /**
     * 
     * @type {string}
     * @memberof PartialAiEnrichmentDetails
     */
    'provider'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAiEnrichmentDetails
     */
    'aimodel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAiEnrichmentDetails
     */
    'initialQuery'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialAiEnrichmentDetails
     */
    'predefinedQueries'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PartialAiEnrichmentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAiEnrichmentDetails
     */
    'book'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialAiEnrichmentDetails
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialAiEnrichmentDetails
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialAiEnrichmentDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialAiEnrichmentDetails
     */
    'type'?: PartialAiEnrichmentDetailsTypeEnum;
}

export const PartialAiEnrichmentDetailsTypeEnum = {
    Ai: 'ai',
    Url: 'url'
} as const;

export type PartialAiEnrichmentDetailsTypeEnum = typeof PartialAiEnrichmentDetailsTypeEnum[keyof typeof PartialAiEnrichmentDetailsTypeEnum];

/**
 * Make all properties in T optional
 * @export
 * @interface PartialBookDetails
 */
export interface PartialBookDetails {
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'authors'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface Queries
 */
export interface Queries {
    /**
     * 
     * @type {string}
     * @memberof Queries
     */
    'initialQuery': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Queries
     */
    'predefinedQueries': Array<string>;
}
/**
 * 
 * @export
 * @interface ReaderEnrichmentDetails
 */
export interface ReaderEnrichmentDetails {
    /**
     * 
     * @type {string}
     * @memberof ReaderEnrichmentDetails
     */
    'bookTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ReaderEnrichmentDetails
     */
    'bookAuthor': string;
    /**
     * 
     * @type {number}
     * @memberof ReaderEnrichmentDetails
     */
    'enrichmentPage': number;
    /**
     * 
     * @type {string}
     * @memberof ReaderEnrichmentDetails
     */
    'enrichmentType': ReaderEnrichmentDetailsEnrichmentTypeEnum;
}

export const ReaderEnrichmentDetailsEnrichmentTypeEnum = {
    Ai: 'ai',
    Url: 'url'
} as const;

export type ReaderEnrichmentDetailsEnrichmentTypeEnum = typeof ReaderEnrichmentDetailsEnrichmentTypeEnum[keyof typeof ReaderEnrichmentDetailsEnrichmentTypeEnum];

/**
 * 
 * @export
 * @interface RegisterFcmTokenRequest
 */
export interface RegisterFcmTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterFcmTokenRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface UpdateBook200Response
 */
export interface UpdateBook200Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateBook200Response
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBook200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UploadBookContents200Response
 */
export interface UploadBookContents200Response {
    /**
     * 
     * @type {string}
     * @memberof UploadBookContents200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UrlDetails
 */
export interface UrlDetails {
    /**
     * 
     * @type {string}
     * @memberof UrlDetails
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UrlEnrichmentDetails
 */
export interface UrlEnrichmentDetails {
    /**
     * 
     * @type {string}
     * @memberof UrlEnrichmentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlEnrichmentDetails
     */
    'book': string;
    /**
     * 
     * @type {number}
     * @memberof UrlEnrichmentDetails
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof UrlEnrichmentDetails
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof UrlEnrichmentDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UrlEnrichmentDetails
     */
    'type': UrlEnrichmentDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UrlEnrichmentDetails
     */
    'url': string;
}

export const UrlEnrichmentDetailsTypeEnum = {
    Ai: 'ai',
    Url: 'url'
} as const;

export type UrlEnrichmentDetailsTypeEnum = typeof UrlEnrichmentDetailsTypeEnum[keyof typeof UrlEnrichmentDetailsTypeEnum];

/**
 * 
 * @export
 * @interface VersionModel
 */
export interface VersionModel {
    /**
     * 
     * @type {string}
     * @memberof VersionModel
     */
    'serverVersion': string;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    'clientApiMinVersion': number;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    'clientApiMaxVersion': number;
}

/**
 * AuthorBookApi - axios parameter creator
 * @export
 */
export const AuthorBookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BookDetails} bookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook: async (bookDetails: BookDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookDetails' is not null or undefined
            assertParamExists('createBook', 'bookDetails', bookDetails)
            const localVarPath = `/authorbook/createBook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBook: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBook', 'id', id)
            const localVarPath = `/authorbook/deleteBook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBookContents: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadBookContents', 'id', id)
            const localVarPath = `/authorbook/downloadBookContents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBooks: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authorbook/getAllBooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBook: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBook', 'id', id)
            const localVarPath = `/authorbook/getBook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialBookDetails} partialBookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBook: async (id: string, partialBookDetails: PartialBookDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBook', 'id', id)
            // verify required parameter 'partialBookDetails' is not null or undefined
            assertParamExists('updateBook', 'partialBookDetails', partialBookDetails)
            const localVarPath = `/authorbook/updateBook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialBookDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBookContents: async (id: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadBookContents', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadBookContents', 'file', file)
            const localVarPath = `/authorbook/uploadBookContents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorBookApi - functional programming interface
 * @export
 */
export const AuthorBookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorBookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BookDetails} bookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBook(bookDetails: BookDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBook(bookDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.createBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBook(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBook200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBook(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.deleteBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBookContents(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadBookContents(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.downloadBookContents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBooks(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartialBookDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBooks(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.getAllBooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBook(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBook(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.getBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialBookDetails} partialBookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBook(id: string, partialBookDetails: PartialBookDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBook200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBook(id, partialBookDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.updateBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBookContents(id: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBookContents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBookContents(id, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.uploadBookContents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthorBookApi - factory interface
 * @export
 */
export const AuthorBookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorBookApiFp(configuration)
    return {
        /**
         * 
         * @param {BookDetails} bookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook(bookDetails: BookDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createBook(bookDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBook(id: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateBook200Response> {
            return localVarFp.deleteBook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBookContents(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadBookContents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBooks(options?: RawAxiosRequestConfig): AxiosPromise<Array<PartialBookDetails>> {
            return localVarFp.getAllBooks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBook(id: string, options?: RawAxiosRequestConfig): AxiosPromise<BookDetails> {
            return localVarFp.getBook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialBookDetails} partialBookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBook(id: string, partialBookDetails: PartialBookDetails, options?: RawAxiosRequestConfig): AxiosPromise<UpdateBook200Response> {
            return localVarFp.updateBook(id, partialBookDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBookContents(id: string, file: File, options?: RawAxiosRequestConfig): AxiosPromise<UploadBookContents200Response> {
            return localVarFp.uploadBookContents(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorBookApi - object-oriented interface
 * @export
 * @class AuthorBookApi
 * @extends {BaseAPI}
 */
export class AuthorBookApi extends BaseAPI {
    /**
     * 
     * @param {BookDetails} bookDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public createBook(bookDetails: BookDetails, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).createBook(bookDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public deleteBook(id: string, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).deleteBook(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public downloadBookContents(id: string, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).downloadBookContents(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public getAllBooks(options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).getAllBooks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public getBook(id: string, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).getBook(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PartialBookDetails} partialBookDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public updateBook(id: string, partialBookDetails: PartialBookDetails, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).updateBook(id, partialBookDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public uploadBookContents(id: string, file: File, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).uploadBookContents(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthorEnrichmentApi - axios parameter creator
 * @export
 */
export const AuthorEnrichmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AiEnrichmentDetails} aiEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAiEnrichment: async (aiEnrichmentDetails: AiEnrichmentDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aiEnrichmentDetails' is not null or undefined
            assertParamExists('createAiEnrichment', 'aiEnrichmentDetails', aiEnrichmentDetails)
            const localVarPath = `/authorenrichment/createAiEnrichment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiEnrichmentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUrlEnrichment: async (urlEnrichmentDetails: UrlEnrichmentDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'urlEnrichmentDetails' is not null or undefined
            assertParamExists('createOrUpdateUrlEnrichment', 'urlEnrichmentDetails', urlEnrichmentDetails)
            const localVarPath = `/authorenrichment/createOrUpdateUrlEnrichment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(urlEnrichmentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookId 
         * @param {GenerateQueriesRequest} generateQueriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQueries: async (bookId: string, generateQueriesRequest: GenerateQueriesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookId' is not null or undefined
            assertParamExists('generateQueries', 'bookId', bookId)
            // verify required parameter 'generateQueriesRequest' is not null or undefined
            assertParamExists('generateQueries', 'generateQueriesRequest', generateQueriesRequest)
            const localVarPath = `/authorenrichment/generateQueries/{bookId}`
                .replace(`{${"bookId"}}`, encodeURIComponent(String(bookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateQueriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate text using specified AI provider
         * @summary Generate text using AI model
         * @param {LLMRequest} lLMRequest Generation request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateText: async (lLMRequest: LLMRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lLMRequest' is not null or undefined
            assertParamExists('generateText', 'lLMRequest', lLMRequest)
            const localVarPath = `/authorenrichment/generateText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lLMRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LLMRequest} lLMRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextStream: async (lLMRequest: LLMRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lLMRequest' is not null or undefined
            assertParamExists('generateTextStream', 'lLMRequest', lLMRequest)
            const localVarPath = `/authorenrichment/generateTextStream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lLMRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichmentBaseList: async (book: string, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getEnrichmentBaseList', 'book', book)
            const localVarPath = `/authorenrichment/getEnrichmentBaseList/{book}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichmentDetails: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getEnrichmentDetails', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEnrichmentDetails', 'id', id)
            const localVarPath = `/authorenrichment/getEnrichmentDetails/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all available AI providers and their models
         * @summary Gets available AI providers and their supported models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authorenrichment/getProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialAiEnrichmentDetails} partialAiEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAiEnrichment: async (id: string, partialAiEnrichmentDetails: PartialAiEnrichmentDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAiEnrichment', 'id', id)
            // verify required parameter 'partialAiEnrichmentDetails' is not null or undefined
            assertParamExists('updateAiEnrichment', 'partialAiEnrichmentDetails', partialAiEnrichmentDetails)
            const localVarPath = `/authorenrichment/updateAiEnrichment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialAiEnrichmentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUrlEnrichment: async (id: string, urlEnrichmentDetails: UrlEnrichmentDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUrlEnrichment', 'id', id)
            // verify required parameter 'urlEnrichmentDetails' is not null or undefined
            assertParamExists('updateUrlEnrichment', 'urlEnrichmentDetails', urlEnrichmentDetails)
            const localVarPath = `/authorenrichment/updateUrlEnrichment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(urlEnrichmentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorEnrichmentApi - functional programming interface
 * @export
 */
export const AuthorEnrichmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorEnrichmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AiEnrichmentDetails} aiEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAiEnrichment(aiEnrichmentDetails: AiEnrichmentDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAiEnrichment(aiEnrichmentDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.createAiEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateUrlEnrichment(urlEnrichmentDetails: UrlEnrichmentDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateUrlEnrichment(urlEnrichmentDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.createOrUpdateUrlEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bookId 
         * @param {GenerateQueriesRequest} generateQueriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateQueries(bookId: string, generateQueriesRequest: GenerateQueriesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateQueries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateQueries(bookId, generateQueriesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.generateQueries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate text using specified AI provider
         * @summary Generate text using AI model
         * @param {LLMRequest} lLMRequest Generation request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateText(lLMRequest: LLMRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LLMResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateText(lLMRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.generateText']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LLMRequest} lLMRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTextStream(lLMRequest: LLMRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTextStream(lLMRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.generateTextStream']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnrichmentBaseList(book: string, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnrichmentDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnrichmentBaseList(book, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.getEnrichmentBaseList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnrichmentDetails(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEnrichmentDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnrichmentDetails(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.getEnrichmentDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all available AI providers and their models
         * @summary Gets available AI providers and their supported models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviders(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAiProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviders(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.getProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialAiEnrichmentDetails} partialAiEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAiEnrichment(id: string, partialAiEnrichmentDetails: PartialAiEnrichmentDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAiEnrichment(id, partialAiEnrichmentDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.updateAiEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUrlEnrichment(id: string, urlEnrichmentDetails: UrlEnrichmentDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUrlEnrichment(id, urlEnrichmentDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.updateUrlEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthorEnrichmentApi - factory interface
 * @export
 */
export const AuthorEnrichmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorEnrichmentApiFp(configuration)
    return {
        /**
         * 
         * @param {AiEnrichmentDetails} aiEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAiEnrichment(aiEnrichmentDetails: AiEnrichmentDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createAiEnrichment(aiEnrichmentDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUrlEnrichment(urlEnrichmentDetails: UrlEnrichmentDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createOrUpdateUrlEnrichment(urlEnrichmentDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookId 
         * @param {GenerateQueriesRequest} generateQueriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQueries(bookId: string, generateQueriesRequest: GenerateQueriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenerateQueries200Response> {
            return localVarFp.generateQueries(bookId, generateQueriesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate text using specified AI provider
         * @summary Generate text using AI model
         * @param {LLMRequest} lLMRequest Generation request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateText(lLMRequest: LLMRequest, options?: RawAxiosRequestConfig): AxiosPromise<LLMResponse> {
            return localVarFp.generateText(lLMRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LLMRequest} lLMRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTextStream(lLMRequest: LLMRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.generateTextStream(lLMRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichmentBaseList(book: string, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnrichmentDetails>> {
            return localVarFp.getEnrichmentBaseList(book, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichmentDetails(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetEnrichmentDetails200Response> {
            return localVarFp.getEnrichmentDetails(book, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all available AI providers and their models
         * @summary Gets available AI providers and their supported models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviders(options?: RawAxiosRequestConfig): AxiosPromise<GetAiProvidersResponse> {
            return localVarFp.getProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialAiEnrichmentDetails} partialAiEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAiEnrichment(id: string, partialAiEnrichmentDetails: PartialAiEnrichmentDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateAiEnrichment(id, partialAiEnrichmentDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUrlEnrichment(id: string, urlEnrichmentDetails: UrlEnrichmentDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateUrlEnrichment(id, urlEnrichmentDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorEnrichmentApi - object-oriented interface
 * @export
 * @class AuthorEnrichmentApi
 * @extends {BaseAPI}
 */
export class AuthorEnrichmentApi extends BaseAPI {
    /**
     * 
     * @param {AiEnrichmentDetails} aiEnrichmentDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public createAiEnrichment(aiEnrichmentDetails: AiEnrichmentDetails, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).createAiEnrichment(aiEnrichmentDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public createOrUpdateUrlEnrichment(urlEnrichmentDetails: UrlEnrichmentDetails, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).createOrUpdateUrlEnrichment(urlEnrichmentDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bookId 
     * @param {GenerateQueriesRequest} generateQueriesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public generateQueries(bookId: string, generateQueriesRequest: GenerateQueriesRequest, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).generateQueries(bookId, generateQueriesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate text using specified AI provider
     * @summary Generate text using AI model
     * @param {LLMRequest} lLMRequest Generation request parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public generateText(lLMRequest: LLMRequest, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).generateText(lLMRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LLMRequest} lLMRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public generateTextStream(lLMRequest: LLMRequest, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).generateTextStream(lLMRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public getEnrichmentBaseList(book: string, page?: number, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).getEnrichmentBaseList(book, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public getEnrichmentDetails(book: string, id: string, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).getEnrichmentDetails(book, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all available AI providers and their models
     * @summary Gets available AI providers and their supported models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public getProviders(options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).getProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PartialAiEnrichmentDetails} partialAiEnrichmentDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public updateAiEnrichment(id: string, partialAiEnrichmentDetails: PartialAiEnrichmentDetails, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).updateAiEnrichment(id, partialAiEnrichmentDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UrlEnrichmentDetails} urlEnrichmentDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public updateUrlEnrichment(id: string, urlEnrichmentDetails: UrlEnrichmentDetails, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).updateUrlEnrichment(id, urlEnrichmentDetails, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccessKey} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccess: async (accessKey: AccessKey, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            assertParamExists('checkAccess', 'accessKey', accessKey)
            const localVarPath = `/general/checkAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/general/getversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccessKey} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAccess(accessKey: AccessKey, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAccess(accessKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.checkAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessKey} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccess(accessKey: AccessKey, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.checkAccess(accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: RawAxiosRequestConfig): AxiosPromise<VersionModel> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {AccessKey} accessKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public checkAccess(accessKey: AccessKey, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).checkAccess(accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVersion(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EnrichmentApi - axios parameter creator
 * @export
 */
export const EnrichmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrichment: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('enrichment', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enrichment', 'id', id)
            const localVarPath = `/enrichment/enrichment/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnrichmentApi - functional programming interface
 * @export
 */
export const EnrichmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnrichmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrichment(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrichment(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnrichmentApi.enrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EnrichmentApi - factory interface
 * @export
 */
export const EnrichmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnrichmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrichment(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.enrichment(book, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnrichmentApi - object-oriented interface
 * @export
 * @class EnrichmentApi
 * @extends {BaseAPI}
 */
export class EnrichmentApi extends BaseAPI {
    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnrichmentApi
     */
    public enrichment(book: string, id: string, options?: RawAxiosRequestConfig) {
        return EnrichmentApiFp(this.configuration).enrichment(book, id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReaderApi - axios parameter creator
 * @export
 */
export const ReaderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderAiContext: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getReaderAiContext', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReaderAiContext', 'id', id)
            const localVarPath = `/reader/getReaderAiContext/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderAiEnrichmentQueries: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getReaderAiEnrichmentQueries', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReaderAiEnrichmentQueries', 'id', id)
            const localVarPath = `/reader/getReaderAiEnrichmentQueries/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderAiProviderDetails: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getReaderAiProviderDetails', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReaderAiProviderDetails', 'id', id)
            const localVarPath = `/reader/getReaderAiProviderDetails/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderEnrichmentDetails: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getReaderEnrichmentDetails', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReaderEnrichmentDetails', 'id', id)
            const localVarPath = `/reader/getReaderEnrichmentDetails/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderHistory: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reader/getReaderHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyReaderScanAction: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('notifyReaderScanAction', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notifyReaderScanAction', 'id', id)
            const localVarPath = `/reader/notifyReaderScanAction/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {AiEnrichmentRequest} aiEnrichmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReaderAiEnrichment: async (book: string, id: string, aiEnrichmentRequest: AiEnrichmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('processReaderAiEnrichment', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('processReaderAiEnrichment', 'id', id)
            // verify required parameter 'aiEnrichmentRequest' is not null or undefined
            assertParamExists('processReaderAiEnrichment', 'aiEnrichmentRequest', aiEnrichmentRequest)
            const localVarPath = `/reader/processReaderAiEnrichment/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aiEnrichmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReaderUrlEnrichment: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('processReaderUrlEnrichment', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('processReaderUrlEnrichment', 'id', id)
            const localVarPath = `/reader/processReaderUrlEnrichment/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterFcmTokenRequest} registerFcmTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerFcmToken: async (registerFcmTokenRequest: RegisterFcmTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerFcmTokenRequest' is not null or undefined
            assertParamExists('registerFcmToken', 'registerFcmTokenRequest', registerFcmTokenRequest)
            const localVarPath = `/reader/registerFcmToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerFcmTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReaderApi - functional programming interface
 * @export
 */
export const ReaderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReaderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderAiContext(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AiContextMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderAiContext(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.getReaderAiContext']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderAiEnrichmentQueries(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Queries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderAiEnrichmentQueries(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.getReaderAiEnrichmentQueries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderAiProviderDetails(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReaderAiProviderDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderAiProviderDetails(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.getReaderAiProviderDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderEnrichmentDetails(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderEnrichmentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderEnrichmentDetails(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.getReaderEnrichmentDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderHistory(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderHistory(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.getReaderHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyReaderScanAction(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyReaderScanAction(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.notifyReaderScanAction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {AiEnrichmentRequest} aiEnrichmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processReaderAiEnrichment(book: string, id: string, aiEnrichmentRequest: AiEnrichmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processReaderAiEnrichment(book, id, aiEnrichmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.processReaderAiEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processReaderUrlEnrichment(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processReaderUrlEnrichment(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.processReaderUrlEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RegisterFcmTokenRequest} registerFcmTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerFcmToken(registerFcmTokenRequest: RegisterFcmTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerFcmToken(registerFcmTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.registerFcmToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReaderApi - factory interface
 * @export
 */
export const ReaderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReaderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderAiContext(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AiContextMessage>> {
            return localVarFp.getReaderAiContext(book, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderAiEnrichmentQueries(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<Queries> {
            return localVarFp.getReaderAiEnrichmentQueries(book, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderAiProviderDetails(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetReaderAiProviderDetails200Response> {
            return localVarFp.getReaderAiProviderDetails(book, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderEnrichmentDetails(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<ReaderEnrichmentDetails> {
            return localVarFp.getReaderEnrichmentDetails(book, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderHistory(options?: RawAxiosRequestConfig): AxiosPromise<Array<HistoryDetails>> {
            return localVarFp.getReaderHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyReaderScanAction(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.notifyReaderScanAction(book, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {AiEnrichmentRequest} aiEnrichmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReaderAiEnrichment(book: string, id: string, aiEnrichmentRequest: AiEnrichmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.processReaderAiEnrichment(book, id, aiEnrichmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processReaderUrlEnrichment(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<UrlDetails> {
            return localVarFp.processReaderUrlEnrichment(book, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterFcmTokenRequest} registerFcmTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerFcmToken(registerFcmTokenRequest: RegisterFcmTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.registerFcmToken(registerFcmTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReaderApi - object-oriented interface
 * @export
 * @class ReaderApi
 * @extends {BaseAPI}
 */
export class ReaderApi extends BaseAPI {
    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public getReaderAiContext(book: string, id: string, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).getReaderAiContext(book, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public getReaderAiEnrichmentQueries(book: string, id: string, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).getReaderAiEnrichmentQueries(book, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public getReaderAiProviderDetails(book: string, id: string, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).getReaderAiProviderDetails(book, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public getReaderEnrichmentDetails(book: string, id: string, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).getReaderEnrichmentDetails(book, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public getReaderHistory(options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).getReaderHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public notifyReaderScanAction(book: string, id: string, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).notifyReaderScanAction(book, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {AiEnrichmentRequest} aiEnrichmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public processReaderAiEnrichment(book: string, id: string, aiEnrichmentRequest: AiEnrichmentRequest, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).processReaderAiEnrichment(book, id, aiEnrichmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public processReaderUrlEnrichment(book: string, id: string, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).processReaderUrlEnrichment(book, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterFcmTokenRequest} registerFcmTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public registerFcmToken(registerFcmTokenRequest: RegisterFcmTokenRequest, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).registerFcmToken(registerFcmTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



