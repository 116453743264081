import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import VariableAutocomplete from './VariableAutocomplete';
import './QueryArea.css';

interface QueryAreaProps {
  initialQueryText: string;
  predefinedQueries: string[];
  onAddPredefinedQuery: (query: string) => void;
  onChangeQuery: (value: string, predefinedIndex?: number) => void;
  onDetelePredefinedQuery: (index: number) => void;
  onTest: (predefinedIndex?: number) => void;
  onAutoGenerateQueries: () => void;
  selectedPdfText: string;
  maxPredefinedQueries?: number;
  isProcessingQuery: boolean;
}

const QueryArea: React.FC<QueryAreaProps> = ({ 
  initialQueryText,
  predefinedQueries,
  onAddPredefinedQuery,
  onChangeQuery,
  onDetelePredefinedQuery,
  onTest,
  onAutoGenerateQueries,
  selectedPdfText,
  maxPredefinedQueries = 4,
  isProcessingQuery,
}) => {
  const handleAddPredefinedQuery = () => {
    onAddPredefinedQuery('');
  };

  const handleInitialQueryChange = (newValue: string) => {
    onChangeQuery(newValue);
  }

  const handlePredefinedQueryChange = (index: number, newValue: string) => {
    // Remove any newline characters
    const sanitizedValue = newValue.replace(/\n/g, '');
    onChangeQuery(sanitizedValue, index);
  };

  const handleDeletePredefinedQuery = (index: number) => {
    onDetelePredefinedQuery(index);
  };

  return (
    <div className="query-container">
      <h6>Type your AI queries below. Type &#123; to show the list of supported query customizations.</h6>
      {(selectedPdfText && selectedPdfText !== '') && (
        <h6>Type {/Mac|iPhone|iPod|iPad/.test(navigator.userAgent) ? 'Cmd' : 'Ctrl'}+Shift+V to insert the text currently highligted in the book</h6>
      )}
      <div className="initial-query">
        <VariableAutocomplete
          value={initialQueryText}
          onChange={(newValue) => handleInitialQueryChange(newValue)}
          selectedPdfText={selectedPdfText} 
          placeholder="Enter initial query here..."
          rows={3}
        />
        <button
          className={`${(initialQueryText.trim() && !isProcessingQuery) ? 'test-query-button' : 'test-query-button-disabled'}`}
          onClick={() => onTest()}
          disabled={!initialQueryText.trim() || isProcessingQuery}
        >
          Test
        </button>
      </div>
      
      <div className="predefined-queries">
        <h6>Predefined Queries (up to {maxPredefinedQueries})</h6>
        <TransitionGroup>
          {predefinedQueries.map((query, index) => (
            <CSSTransition
              key={index}
              timeout={200}
              classNames="predefined-query-row"
            >
              <div className="predefined-query-row">
                <VariableAutocomplete
                  value={query}
                  onChange={(newValue) => handlePredefinedQueryChange(index, newValue)}
                  rows={1}
                  placeholder="Enter predefined query..."
                />
                <button
                  className="delete-query-button"
                  onClick={() => handleDeletePredefinedQuery(index)}
                >
                  Delete
                </button>
                <button
                  className={`${(query.trim() && !isProcessingQuery) ? 'test-query-button' : 'test-query-button-disabled'}`}
                  onClick={() => onTest(index)}
                  disabled={!query.trim() || isProcessingQuery}
                >
                  Test
                </button>
             </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        {predefinedQueries.length < maxPredefinedQueries ? (<button
          className={`${predefinedQueries.length < maxPredefinedQueries ? 'new-query-button' : 'new-query-button-disabled'}`}
          onClick={handleAddPredefinedQuery}
          disabled={predefinedQueries.length >= maxPredefinedQueries}
        >
          Add query
        </button>
        ) : null}
      </div>
      <button
        className="auto-generate-button"
        onClick={onAutoGenerateQueries}
        disabled={isProcessingQuery}
      >
        {isProcessingQuery ? 'Processing...' : 'Auto-generate queries'}
      </button>
    </div>
  );
};

export default QueryArea;