import React, { useState, useEffect, useRef } from 'react';
import './EnrichmentDialog.css';
import { apiService } from './ApiService';
import { AiEnrichmentDetails, AiModel, AiProvider, AuthorEnrichmentApiFactory, EnrichmentDetails, EnrichmentDetailsTypeEnum, GetAiProvidersResponse, LLMRequest, LLMResponseUsage, UrlEnrichmentDetails, UrlEnrichmentDetailsTypeEnum } from './osakaserver_api';
import QueryArea from './QueryArea';
import { AxiosProgressEvent, RawAxiosRequestConfig } from 'axios';
import LLMChatWindow, { LLMChatWindowMethods } from './LLMChatWindow';

interface EnrichmentDialogProps {
  enrichmentBase: EnrichmentDetails;
  pageText: string;
  selectedText: string;
  onClose: (refreshEnrichments: boolean) => void;
}

interface ParsedQueryLink {
  title: string;
  url: string;
}

const EnrichmentDialog: React.FC<EnrichmentDialogProps> = ({ enrichmentBase, pageText, selectedText, onClose }) => {
  const [title, setTitle] = useState<string>('');
  const [enrichmentType, setEnrichmentType] = useState(enrichmentBase.type);
  const [enrichment, setEnrichment] = useState<AiEnrichmentDetails | UrlEnrichmentDetails | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<AiProvider | null>(null);
  const [selectedModel, setSelectedModel] = useState<AiModel | null>(null);
  const [initialQueryText, setInitialQueryText] = useState<string>('');
  const [predefinedQueries, setPredefinedQueries] = useState<string[]>([]);
  const [providers, setProviders] = useState<AiProvider[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [providerError, setProviderError] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [refreshEnrichments, setRefreshEnrichments] = useState<boolean>(false);
  const [hasBeenModified, setHasBeenModified] = useState<boolean>(false);
  const [isProcessingQuery, setIsProcessingQuery] = useState(false);

  const isInitialized = useRef(false);
  const responseGroupRef = useRef<LLMChatWindowMethods>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const maxPredefinedQueries = 4;

  const fetchEnrichmentDetails = async (): Promise<AiEnrichmentDetails | UrlEnrichmentDetails> => {
    try {
      if (!enrichmentBase.id) {
        throw new Error('Enrichment ID is missing');
      }
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = AuthorEnrichmentApiFactory(apiConfig); // Get the API instance
      const response = await api.getEnrichmentDetails(enrichmentBase.book, enrichmentBase.id, apiParams);
      if (!response.data) {
        throw new Error(`HTTP error status: ${response.status}`);
      }    
      return await response.data;
    }
    catch (error) {
      console.error('Error fetching AI providers:', error);
      throw error;
    }
  };

  const fetchAiProviders = async (): Promise<GetAiProvidersResponse> => {
    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = AuthorEnrichmentApiFactory(apiConfig); // Get the API instance
      const response = await api.getProviders(apiParams);
      if (!response.data) {
        throw new Error(`HTTP error status: ${response.status}`);
      }    
      return await response.data;
    }
    catch (error) {
      console.error('Error fetching AI providers:', error);
      throw error;
    }
  };

  const parseQueryLink = (query: string): ParsedQueryLink | null => {
    const regex = /<link\s+title\s*=\s*"(.+?)"\s+url\s*=\s*"(.+?)"\s*\/?\s*>/;
    const match = query.match(regex);
    if (match && match.length === 3) {
      const title = match[1];
      const url = match[2];

      try {
        const parsedUrl = new URL(url);
        if (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') {
          return { title, url };
        }
      }
      catch {
        // Invalid URL format
        return null;
      }
    }
    return null;
  };

  const handleSaveCloseEnrichment = async () => {
    if (hasBeenModified) {
      await handleApplyEnrichment();
      // Close the modal after saving the enrichment and refresh all enrichments
      onClose(true);
    }
    else {
      onClose(false);
    }
  }

  const handleApplyEnrichment = async () => {
    if (!enrichment) {
      console.error('Enrichment details are missing');
      return;
    }

    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorEnrichmentApiFactory(apiConfig);
    if (enrichmentType === 'ai') {
      const aiEnrichment = {
        id: enrichment.id,
        book: enrichment.book,
        page: enrichment.page,
        offset: enrichment.offset,
        title,
        type: UrlEnrichmentDetailsTypeEnum.Ai,
        initialQuery: initialQueryText,
        predefinedQueries,
        provider: selectedProvider?.id || '',
        aimodel: selectedModel?.id || '',
      };

      if (enrichment.id) {
        await api.updateAiEnrichment(enrichment.id!, aiEnrichment, apiParams);
      }
      else {
        const response = await api.createAiEnrichment(aiEnrichment, apiParams);
        if (response && response.data) {
          enrichment.id = response.data;
        }
        else {
          console.error('Failed to create AI enrichment:', response);
        }
      }
    }
    else if (enrichmentType === 'url') {
      const urlEnrichment = {
        id: enrichment.id,
        book: enrichment.book,
        page: enrichment.page,
        offset: enrichment.offset,
        title,
        type: UrlEnrichmentDetailsTypeEnum.Url,
        url: url,
      };

      const response = await api.createOrUpdateUrlEnrichment(urlEnrichment, apiParams);
      if (response && response.data) {
        enrichment.id = response.data;
      }
      else {
        console.error('Failed to create URL enrichment:', response);
      }
    }

    setHasBeenModified(false);
    setRefreshEnrichments(true);
  };
  
  const handleCancelEnrichment = () => {
    // Close the modal without saving the enrichment
    onClose(refreshEnrichments);
  };

  const handleTestEnrichment = () => {
    let bookId = '';
    let enrichmentId = '';
    if (!enrichment || !enrichment.id) {
      if (!enrichmentBase || !enrichmentBase.id) {
        console.error('Enrichment ID is missing');
        return;
      }
      else {
        bookId = enrichmentBase.book;
        enrichmentId = enrichmentBase.id;
      }
    }
    else {
      bookId = enrichment.book;
      enrichmentId = enrichment.id;
    }
    if (enrichmentType === 'ai') {
      window.open(apiService.getReaderEnrichmentAIUrl(bookId, enrichmentId), '_blank');
    }
    else if (enrichmentType === 'url') {
      const urlEnrichment = enrichment as UrlEnrichmentDetails;
      window.open(urlEnrichment.url, '_blank');
    }
  }

  const handleAddPredefinedQuery = (query: string) => {
    if (predefinedQueries.length >= maxPredefinedQueries) {
      return;
    }
    setPredefinedQueries([...predefinedQueries, '']);
    setHasBeenModified(true);
  };

  const handleChangeQuery = (value: string, predefinedIndex?: number) => {
    if (predefinedIndex === undefined) {
      setInitialQueryText(value);
    }
    else {
      const newQueries = [...predefinedQueries];
      newQueries[predefinedIndex] = value;
      setPredefinedQueries(newQueries);
    }
    setHasBeenModified(true);
  };

  const handleDeletePredefinedQuery = (index: number) => {
    const newQueries = predefinedQueries.filter((_, i) => i !== index);
    setPredefinedQueries(newQueries);
    setHasBeenModified(true);
  };

  const handleTestQuery = async (predefinedIndex?: number) => {
    if (!responseGroupRef.current) return;
  
    let queryText: string;
    if (typeof predefinedIndex === 'number') {
      queryText = predefinedQueries[predefinedIndex];
    }
    else {
      queryText = initialQueryText;
    }
    
    if (queryText.trim()) {
      try {
        const parsedQueryLink = parseQueryLink(queryText);
        if (parsedQueryLink) {
          window.open(parsedQueryLink.url, '_blank');
        }
        else {
          await responseGroupRef.current.runQuery(queryText);
        }
      }
      catch (error) {
        console.error('Failed to run query:', error);
      }
    }
  };

  const initializeAi = async (refresh: boolean) => {
    setIsLoading(true);
    setProviderError('');
  
    try {
      const { providers } = await fetchAiProviders();
      setProviders(providers);
  
      if (enrichmentBase.id && enrichmentBase.type === 'ai') {
        if (refresh) {
          const fetchedEnrichment = await fetchEnrichmentDetails();
          if (!fetchedEnrichment || fetchedEnrichment.type !== 'ai') {
            throw new Error('Invalid enrichment details');
          }
          const aiEnrichment = fetchedEnrichment as AiEnrichmentDetails;
          setEnrichment(aiEnrichment);
          setInitialQueryText(aiEnrichment.initialQuery || '');
          setPredefinedQueries(aiEnrichment.predefinedQueries || []);
    
          const provider = providers.find(provider => provider.id === aiEnrichment.provider) || providers[0];
          const model = provider?.models.find(model => model.id === aiEnrichment.aimodel) || provider?.models.find(model => model.default) || provider?.models[0];
          setSelectedProvider(provider);
          setSelectedModel(model);
        }
      }
      else {
        const defaultProvider = providers[0];
        const defaultModel = defaultProvider?.models.find(model => model.default) || defaultProvider?.models[0];
        
        setEnrichment({
          book: enrichmentBase.book,
          page: enrichmentBase.page,
          offset: enrichmentBase.offset,
          title: enrichmentBase.title,
          type: 'ai',
          provider: selectedProvider?.id || defaultProvider?.id || '',
          aimodel: selectedModel?.id || defaultModel?.id || '',
          initialQuery: initialQueryText,
          predefinedQueries: predefinedQueries,
        });
        
        setSelectedProvider(selectedProvider || defaultProvider);
        setSelectedModel(selectedModel || defaultModel);
      }
    }
    catch (error) {
      setProviderError('Failed to load AI providers and models');
      console.error('Error loading providers and models:', error);
    }
    finally {
      setIsLoading(false);
    }
  };
  
  const initializeUrl = async (refresh: boolean) => {
    if (refresh && enrichmentBase.id && enrichmentBase.type === 'url') {
      const fetchedEnrichment = await fetchEnrichmentDetails();
      if (!fetchedEnrichment || fetchedEnrichment.type !== 'url') {
        throw new Error('Invalid enrichment details');
      }
      const urlEnrichment = fetchedEnrichment as UrlEnrichmentDetails;
      setEnrichment(urlEnrichment);
      setTitle(urlEnrichment.title || '');
      setUrl(urlEnrichment.url);
    }
    else {
      setEnrichment({
        book: enrichmentBase.book,
        page: enrichmentBase.page,
        offset: enrichmentBase.offset,
        title: enrichmentBase.title,
        type: 'url',
        url,
      });
    }
  }

  const initialize = async (type: EnrichmentDetailsTypeEnum, refresh: boolean) => {
    if (type === 'ai') {
      await initializeAi(refresh);
    }
    else if (type === 'url') {
      await initializeUrl(refresh);
    }
  }

  useEffect(() => {
    if (!isInitialized.current) {
      initialize(enrichmentBase.type, true);
      setTitle(enrichmentBase.title || '');
      isInitialized.current = true;
    }

    // Cleanup function to reset initialization flag when component unmounts
    return () => {
      isInitialized.current = false;
    };
  }, []);

  useEffect(() => {
    initialize(enrichmentType, false);
  }, [enrichmentType]);

  // Reset model selection when provider changes
  useEffect(() => {
    setSelectedModel(selectedProvider?.models.find(model => model.default) || null);
  }, [selectedProvider]);

  const handleProcessingStateChange = (isProcessingQuery: boolean) => {
    console.log('Processing state changed:', isProcessingQuery); // Add this for debugging
    setIsProcessingQuery(isProcessingQuery);
  };
  
  const handleAutoGenerateQueries = async () => {
    if (!enrichment || !enrichment.book) {
      console.error('Enrichment details are missing');
      return;
    }
    if (!selectedProvider?.id) {
      console.error('AI provider is not selected');
      return;
    }
  
    setIsProcessingQuery(true);
    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = AuthorEnrichmentApiFactory(apiConfig);

      const queries = await api.generateQueries(enrichment.book, {
        provider: selectedProvider.id,
        aimodel: selectedModel?.id,
        offset: selectedText.length > 0 ? undefined : enrichment.offset,
        contents: selectedText.length > 0 ? selectedText : pageText,
      }, apiParams);
      
      setInitialQueryText(queries.data.initialQuery);
      setPredefinedQueries(queries.data.followupQueries);
      setHasBeenModified(true);
    }
    catch (error) {
      console.error('Error auto-generating queries:', error);
    }
    finally {
      setIsProcessingQuery(false);
    }
  };

  return (
    <div className="edit-enrichment-modal-overlay">
      <div className="edit-enrichment-modal">

        <div className="title-input-container">
          <h2>Configure Enrichment</h2>
          <input
            type="text"
            className="title-input"
            placeholder="Enter enrichment title..."
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setHasBeenModified(true);
            }}
          />
        </div>

        <div className="modal-header">
          <select 
            className="enrichment-type-select"
            value={enrichmentType} 
            onChange={(e) => {
              const type = e.target.value as 'ai' | 'url';
              setEnrichmentType(type);
              setHasBeenModified(true);
            }}
          >
            <option value="ai">AI query</option>
            <option value="url">URL</option>
          </select>
        </div>

        {enrichmentType === 'ai' ? (
          <div className="modal-ai-content">
            <div className="modal-ai-set-content">
              <QueryArea
                initialQueryText={initialQueryText}
                predefinedQueries={predefinedQueries}
                onAddPredefinedQuery={handleAddPredefinedQuery}
                onChangeQuery={handleChangeQuery}
                onDetelePredefinedQuery={handleDeletePredefinedQuery}
                onTest={handleTestQuery}
                onAutoGenerateQueries={handleAutoGenerateQueries}
                selectedPdfText={selectedText}
                isProcessingQuery={isProcessingQuery}
              />
              
              <div className="selection-container">
                <div className="providers-section">
                  <div className="provider-section-header">
                    <h3>Select AI Provider</h3>
                  </div>
                  <select
                    className="provider-select"
                    value={selectedProvider?.id || ''}
                    onChange={(e) => {
                      const provider = providers?.find(provider => provider.id === e.target.value);
                      if (provider && provider !== selectedProvider) {
                        setSelectedProvider(provider);
                        (enrichment as AiEnrichmentDetails).provider = e.target.value;
                        setHasBeenModified(true);
                      }
                    }}
                    disabled={isLoading}
                  >
                    {!selectedProvider && <option value="">Select provider</option>}
                    {providers.map(provider => (
                      <option key={provider.id} value={provider.id}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </div>
          
                <div className="models-section">
                  <div className="model-section-header">
                    <h3>Select AI Model</h3>
                  </div>
                  <select
                    className="model-select"
                    value={selectedModel?.id || ''}
                    onChange={(e) => {
                      setSelectedModel(selectedProvider?.models.find(model => model.id === e.target.value) || null);
                      (enrichment as AiEnrichmentDetails).aimodel = e.target.value;
                      setHasBeenModified(true);
                    }}
                    disabled={!selectedProvider || isLoading}
                  >
                    {!selectedModel && <option value="">Select model</option>}
                    {selectedProvider?.models.map(model => (
                      <option key={model.id} value={model.id}>
                        {model.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            
            <div className="modal-ai-test-content">
              <LLMChatWindow
                ref={responseGroupRef}
                provider={selectedProvider?.id || ''}
                model={selectedModel?.id || ''}
                onProcessingStateChange={handleProcessingStateChange}
              />
            </div>
          </div>
        ) : (enrichmentType === 'url' && (
          <div className="modal-url-content">
            <input
              type="text"
              placeholder="Enter URL..."
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                (enrichment as UrlEnrichmentDetails).url = e.target.value;
                setHasBeenModified(true);
              }}
            />
          </div>
        ))}

        <div className="action-buttons">
          <button className="button cancel" onClick={handleCancelEnrichment}>{hasBeenModified ? 'Cancel' : 'Close'}</button>
          <button className="button saveclose" onClick={handleSaveCloseEnrichment}>Save & Close</button>
          <button className="button apply" onClick={handleApplyEnrichment} disabled={!hasBeenModified}>Apply</button>
          <button className="button test" onClick={handleTestEnrichment} disabled={hasBeenModified}>Test...</button>
        </div>
      </div>
    </div>
  );
};

export default EnrichmentDialog;