import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { EnrichmentDetails } from './osakaserver_api';
import './EnrichmentIndicator.css';

interface EnrichmentIndicatorProps {
  enrichment: EnrichmentDetails;
  onQRCodeClick: (enrichment: EnrichmentDetails) => void;
  generateQRCodeValue: (enrichment: EnrichmentDetails) => string;
}

const EnrichmentIndicator: React.FC<EnrichmentIndicatorProps> = ({ 
  enrichment, 
  onQRCodeClick, 
  generateQRCodeValue 
}) => {
  return (
    <div 
      className="enrichment-indicator"
      style={{top: `${enrichment.offset}%`}}
      onClick={(e) => {
        e.stopPropagation();
        if (enrichment.id) {
          onQRCodeClick(enrichment);
        }
      }}
    >
      {enrichment.id && (
        <div className="qr-code-container">
          <QRCodeCanvas 
            value={generateQRCodeValue(enrichment)} 
            size={50} 
            className="qr-code"
          />
          {enrichment.title && (
            <div className="enrichment-title">
              {enrichment.title}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EnrichmentIndicator;
