import React, { useRef, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { BookDetails, EnrichmentDetails, AuthorBookApiFactory, AuthorEnrichmentApiFactory } from './osakaserver_api';
import EnrichmentDialog from './EnrichmentDialog';
import EnrichmentIndicator from './EnrichmentIndicator';
import LoadingOverlay from './LoadingOverlay';
import QRCode from 'qrcode';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { apiService } from './ApiService';
import './MainContent.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface MainContentProps {
  currentBook: BookDetails | null;
  user: any | null;
  onBookUpdated: () => Promise<void>;
  onBookEdit: (book: BookDetails) => void;
  onBookDelete: (book: BookDetails) => void;
}

const MainContent: React.FC<MainContentProps> = ({
  currentBook,
  user,
  onBookUpdated,
  onBookEdit,
  onBookDelete
}) => {
  const [bookContents, setBookContents] = useState<Blob | null>(null);
  const [bookContentsArray, setBookContentsArray] = useState<ArrayBuffer | null>(null);
  const [pdfDocument, setPdfDocument] = useState<pdfjs.PDFDocumentProxy | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentScale, setCurrentScale] = useState(1.0);
  const [pdfPageWidth, setPdfPageWidth] = useState<number | null>(null);
  const [currentPageText, setCurrentPageText] = useState<string>('');
  const [enrichments, setEnrichments] = useState<EnrichmentDetails[]>([]);
  const [showEnrichmentInfoModal, setShowEnrichmentInfoModal] = useState<boolean>(false);
  const [currentEnrichment, setCurrentEnrichment] = useState<EnrichmentDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedText, setSelectedText] = useState<string>('');

  const viewerRef = useRef<HTMLDivElement | null>(null);
  const pdfPage = useRef<HTMLDivElement | null>(null);
  const pdfContainerViewerRef = useRef<HTMLDivElement | null>(null);
  const pdfSidebarRef = useRef<HTMLDivElement | null>(null);
  const showEnrichmentInfoModalRef = useRef(showEnrichmentInfoModal);
  const lastSelection = useRef<{ text: string; range: Range | null }>({ text: '', range: null });

  useEffect(() => {
    if (currentBook?.id) {
      loadBookContents();
      fetchEnrichments();
    }
  }, [currentBook]);

  useEffect(() => {
    alignSidebarHeight();
  }, [pdfPageWidth, currentScale, numPages]);

  useEffect(() => {
    if (pdfContainerViewerRef.current) {
      updatePdfPageWidth(pdfContainerViewerRef.current.clientWidth);

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            updatePdfPageWidth(entry.contentRect.width);
          }
        }
      });

      resizeObserver.observe(pdfContainerViewerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [pdfContainerViewerRef.current]);

  useEffect(() => {
    showEnrichmentInfoModalRef.current = showEnrichmentInfoModal;
  }, [showEnrichmentInfoModal]);

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const loadBookContents = async () => {
    if (!currentBook?.id) return;

    setLoading(true);
    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig('arraybuffer');
      const api = AuthorBookApiFactory(apiConfig);
      const contents = await api.downloadBookContents(currentBook.id, apiParams);
      const contentsData = contents as unknown as { data: ArrayBuffer };
      if (contentsData.data && contentsData.data.byteLength > 0) {
        await processContentArray(contentsData.data);
      }
    } catch (err) {
      console.error('Failed to fetch book contents:', err);
    }
    setLoading(false);
  };

  const processContentArray = async (contentsArray: ArrayBuffer) => {
    setBookContentsArray(contentsArray);
    setBookContents(new Blob([contentsArray], { type: 'application/pdf' }));
    await updateCurrentPage(0);
    const pdf = await pdfjs.getDocument(contentsArray).promise;
    if (pdf) {
      setPdfDocument(pdf);
    }
  };

  const fetchEnrichments = async () => {
    if (!currentBook?.id) return;

    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorEnrichmentApiFactory(apiConfig);
    const fetchedEnrichments = await api.getEnrichmentBaseList(currentBook.id, undefined, apiParams);
    if (fetchedEnrichments.data) {
      setEnrichments(fetchedEnrichments.data);
    }
  };

  const handleUploadContents = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !currentBook?.id) return;

    setLoading(true);

    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = AuthorBookApiFactory(apiConfig);
      const result = await api.uploadBookContents(currentBook.id, file, apiParams);
      
      if (result.data) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const content = e.target?.result;
          if (content) {
            await processContentArray(content as ArrayBuffer);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (err) {
      console.error('Failed to upload contents:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleGeneratePDF = async () => {
    if (!pdfDocument) return;

    setLoading(true);
    try {
      const pdfData = await pdfDocument.getData();
      const pdfLibDoc = await PDFDocument.load(pdfData.buffer.slice(0), {
        ignoreEncryption: true,
        updateMetadata: false,
        throwOnInvalidObject: false
      });

      const helveticaFont = await pdfLibDoc.embedFont(StandardFonts.Helvetica);
      const pages = pdfLibDoc.getPages();

      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        const { height } = page.getSize();

        // Sort enrichments by offset to maintain consistent layering
        const pageEnrichments = enrichments
          .filter(e => e.page === i)
          .sort((a, b) => a.offset - b.offset);

        for (const enrichment of pageEnrichments) {
          if (!enrichment.id) continue;

          const qrCodeValue = generateQRCodeValue(enrichment);
          const qrCodeImageDataUrl = await QRCode.toDataURL(qrCodeValue, {
            errorCorrectionLevel: 'H',
            margin: 1
          });

          const response = await fetch(qrCodeImageDataUrl);
          const qrCodeImageBytes = await response.arrayBuffer();
          const qrImage = await pdfLibDoc.embedPng(qrCodeImageBytes);
          const qrSize = 40;
          const qrImageDims = qrImage.scale(qrSize / qrImage.width);

          const yPosition = height - (enrichment.offset / 100) * height;
          const xPosition = 10;

          page.drawImage(qrImage, {
            x: xPosition,
            y: yPosition - qrImageDims.height,
            width: qrImageDims.width,
            height: qrImageDims.height,
          });

          if (enrichment.title) {
            try {
              const fontSize = 8;
              const titleWidth = helveticaFont.widthOfTextAtSize(enrichment.title, fontSize);
              const maxWidth = qrSize;

              let displayTitle = enrichment.title;
              if (titleWidth > maxWidth) {
                let truncated = false;
                while (helveticaFont.widthOfTextAtSize(displayTitle + '...', fontSize) > maxWidth && displayTitle.length > 0) {
                  displayTitle = displayTitle.slice(0, -1);
                  truncated = true;
                }
                if (truncated) {
                  displayTitle += '...';
                }
              }

              const titleX = xPosition + (qrSize - helveticaFont.widthOfTextAtSize(displayTitle, fontSize)) / 2;
              const titleY = yPosition - qrImageDims.height - fontSize - 2;

              page.drawText(displayTitle, {
                x: titleX,
                y: titleY,
                size: fontSize,
                font: helveticaFont,
                color: rgb(0, 0, 0),
              });
            } catch (error) {
              console.error(`Unable to render title (${enrichment.title}):`, error);
            }
          }
        }
      }

      const pdfBytes = await pdfLibDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'enrichedbook.pdf';
      link.click();
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF. The document might be corrupted or have incompatible features.');
    } finally {
      setLoading(false);
    }
  };

  const handleBookEditClick = () => {
    if (!currentBook) {
      console.error('No book selected');
      return;
    }
    onBookEdit(currentBook);
  };

  const handleBookDeleteClick = () => {
    if (!currentBook) {
      console.error('No book selected');
      return;
    }
    onBookDelete(currentBook);
  };

  const updatePdfPageWidth = (newWidth: number) => {
    const adjustedWidth = newWidth > 300 ? newWidth : 300;
    setPdfPageWidth(adjustedWidth - 150);
  };

  const alignSidebarHeight = () => {
    if (pdfPage.current && pdfSidebarRef.current) {
      pdfSidebarRef.current.style.height = `${pdfPage.current.clientHeight}px`;
    }
  };

  const onDocumentLoadSuccess = () => {
    setNumPages(pdfDocument?.numPages!);
  };

  const onDocumentLoadError = (error: any) => {
    console.error('Error while loading the document:', error);
  };

  const handleNextPage = async () => {
    if (numPages && currentPage < (numPages - 1)) {
      await updateCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 0) {
      await updateCurrentPage(currentPage - 1);
    }
  };

  const updateCurrentPage = async (newPage: number) => {
    setCurrentPage(newPage);
    alignSidebarHeight();
  };

  const extractPageText = async () => {
    if (!pdfDocument) return '';

    try {
      const page = await pdfDocument.getPage(currentPage + 1);
      const textContent = await page.getTextContent();
      const pageText = textContent.items
        .map((item: any) => item.str)
        .join(' ');
      
      setCurrentPageText(pageText);
      return pageText;
    } catch (error) {
      console.error('Error extracting text from page:', error);
      return '';
    }
  };

  const onPageRenderSuccess = () => {
    extractPageText();
    alignSidebarHeight();
  };

  const handleSidebarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const sterileZoneSize = 10;
    const qrCodes = document.querySelectorAll('.enrichment-indicator');
    let clickedInsideQRCode = false;

    qrCodes.forEach((qrCode) => {
      const rect = qrCode.getBoundingClientRect();
      if (
        e.clientX > rect.left - sterileZoneSize &&
        e.clientX < rect.right + sterileZoneSize &&
        e.clientY > rect.top - sterileZoneSize &&
        e.clientY < rect.bottom + sterileZoneSize
      ) {
        clickedInsideQRCode = true;
      }
    });

    if (!clickedInsideQRCode) {
      const sidebarRect = e.currentTarget.getBoundingClientRect();
      const yOffsetPercent = ((e.clientY - sidebarRect.top) / sidebarRect.height) * 100;
      handleNewEnrichmentClick(yOffsetPercent);
    }
  };

  const handleNewEnrichmentClick = (yOffsetPercent: number) => {
    if (!currentBook?.id) return;

    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      setSelectedText(selection.toString());
    }
    
    setCurrentEnrichment({
      book: currentBook.id,
      type: 'ai',
      page: currentPage,
      offset: yOffsetPercent,
    });
    setShowEnrichmentInfoModal(true);
  };

  const handleQRCodeClick = (enrichment: EnrichmentDetails) => {
    if (!enrichment.id) return;

    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      setSelectedText(selection.toString());
    }
    setCurrentEnrichment(enrichment);
    setShowEnrichmentInfoModal(true);
  };

  const generateQRCodeValue = (enrichment: EnrichmentDetails) => {
    if (!enrichment.id) return '';
    return `${apiService.getBackendBaseUrl()}/api/v1/enrichment/enrichment/${enrichment.book}/${enrichment.id}`;
  };

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      lastSelection.current = {
        text: selection.toString(),
        range: selection.getRangeAt(0),
      };
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    const selection = window.getSelection();
    if (lastSelection.current.range) {
      if (showEnrichmentInfoModalRef.current) {
        selection?.removeAllRanges();
        selection?.addRange(lastSelection.current.range);
      } else if (pdfSidebarRef.current && pdfSidebarRef.current.contains(e.target as Node)) {
        e.preventDefault();
      } else {
        lastSelection.current = { text: '', range: null };
      }
    }
  };

  const closeEnrichmentDialog = async (refreshEnrichments: boolean) => {
    setCurrentEnrichment(null);
    setSelectedText('');
    setShowEnrichmentInfoModal(false);

    if (refreshEnrichments) {
      await fetchEnrichments();
    }
  };

  if (!currentBook) {
    return (
      <div className="content">
        <h1 className="welcome-message">Welcome to Enriched Books</h1>
        {user ? (
          <>
            <h2 className="welcome-message-small">Welcome, {user.displayName}!</h2>
            <h2 className="welcome-message-smaller">Please select a book on the left side of the page</h2>
            <h2 className="welcome-message-smaller">or click the "+" button to create a new book.</h2>
          </>
        ) : (
          <p className="welcome-message-small">Please sign in to access your account.</p>
        )}
      </div>
    );
  }

  return (
    <div className="content">
      <div className="book-content-title">
        <h3>{currentBook.title}</h3>
        <div className="title-bar-buttons">
          <button onClick={handleBookEditClick}>Edit Title...</button>
          <button onClick={handleGeneratePDF} disabled={bookContents == null}>Generate PDF</button>
          <button onClick={handleBookDeleteClick}>Delete...</button>
        </div>
      </div>
      
      {bookContents ? (
        <>
          <div className="book-editor">
            <div className="pdf-viewer" ref={viewerRef}>
              <div className="pdf-container" ref={pdfContainerViewerRef}>
                <div className="pdf-sidebar" onClick={handleSidebarClick} ref={pdfSidebarRef}>
                  {enrichments.map((enrichment) => (
                    enrichment.page === currentPage && (
                      <EnrichmentIndicator
                        key={enrichment.id}
                        enrichment={enrichment}
                        onQRCodeClick={handleQRCodeClick}
                        generateQRCodeValue={generateQRCodeValue}
                      />
                    )
                  ))}
                </div>
                <Document 
                  file={bookContents} 
                  onLoadSuccess={onDocumentLoadSuccess} 
                  onLoadError={onDocumentLoadError}
                >
                  {pdfPageWidth && (
                    <Page
                      pageNumber={currentPage + 1}
                      width={pdfPageWidth}
                      renderTextLayer={true}
                      renderAnnotationLayer={true}
                      scale={currentScale}
                      onRenderSuccess={onPageRenderSuccess}
                      inputRef={pdfPage}
                    />
                  )}
                </Document>
              </div>
              {numPages && (
                <div className="pdf-controls">
                  <div className="page-controls">
                    <button onClick={handlePreviousPage} disabled={currentPage === 0}>
                      Previous Page
                    </button>
                    <span>
                      Page {currentPage + 1} of {numPages}
                    </span>
                    <button onClick={handleNextPage} disabled={currentPage === (numPages - 1)}>
                      Next Page
                    </button>
                  </div>
                  <div className="page-controls">
                    <button onClick={() => setCurrentScale(currentScale - 0.2)} disabled={currentScale <= 0.3}>-</button>
                    <span>{Math.round(currentScale * 100)}%</span>
                    <button onClick={() => setCurrentScale(currentScale + 0.2)} disabled={currentScale >= 1.0}>+</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <p>Contents have not been uploaded for this book.</p>
          <div className="upload-contents-container">
            <input
              type="file"
              id="upload-contents"
              style={{ display: 'none' }}
              accept=".txt,.doc,.pdf,.epub"
              onChange={handleUploadContents}
            />
            <button 
              onClick={() => document.getElementById('upload-contents')?.click()} 
              className="upload-contents-style-button"
            >
              Upload Book Contents
            </button>
          </div>
        </>
      )}

      {(showEnrichmentInfoModal && currentEnrichment) && (
        <EnrichmentDialog
          enrichmentBase={currentEnrichment}
          pageText={currentPageText}
          selectedText={selectedText}
          onClose={closeEnrichmentDialog}
        />
      )}

      {loading && <LoadingOverlay />}
    </div>
  );
};

export default MainContent;