import React, { useState, useRef, useEffect, useCallback } from 'react';
import { LLMRequest, LLMResponseUsage } from './osakaserver_api';
import { AuthorEnrichmentApiFactory } from './osakaserver_api';
import { apiService } from './ApiService';
import { AxiosProgressEvent, RawAxiosRequestConfig } from 'axios';
import './LLMChatWindow.css';

export interface LLMChatWindowMethods {
  runQuery: (query: string) => Promise<void>;
}

interface Message {
  id: string;
  type: 'user' | 'assistant';
  content: string;
  timestamp: Date;
  summary?: ResponseSummary;
}

interface ResponseSummary {
  provider: string;
  model: string;
  usage: LLMResponseUsage;
}

interface LLMChatWindowProps {
  provider: string;
  model: string;
  initialMessage?: string;
  onProcessingStateChange: (isProcessing: boolean) => void; // Add this
}

const LLMChatWindow = React.forwardRef<LLMChatWindowMethods, LLMChatWindowProps>((props, ref) => {
  const {
    provider,
    model,
    initialMessage = '',
    onProcessingStateChange
  } = props;

  const [messages, setMessages] = useState<Message[]>([]);
  const [currentMessage, setCurrentMessage] = useState(initialMessage);
  const [isReceivingResponse, _setIsReceivingResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseSummary, setResponseSummary] = useState<ResponseSummary | null>(null);
 
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const svgStateRef = useRef<{ isCollecting: boolean; buffer: string }>({ isCollecting: false, buffer: '' });
  const processedTextLengthRef = useRef<number>(0);
  const isAssistantRespondingRef = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const setIsReceivingResponse = (value: boolean) => {
    _setIsReceivingResponse(value);
    onProcessingStateChange(value);
  };

  useEffect(() => {
    // Cancel any ongoing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
    
    // Reset all state
    setCurrentMessage(initialMessage);
    setErrorMessage('');
    setResponseSummary(null);
    setIsReceivingResponse(false);
    isAssistantRespondingRef.current = false;
  }, [provider, model, initialMessage]); // Remove currentProvider, currentModel from deps
  
  useEffect(() => {
    if (initialMessage && messages.length === 0) {
      setCurrentMessage(initialMessage);
    }
  }, [initialMessage]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      const element = chatWindowRef.current;
      const isNearBottom = element.scrollHeight - element.scrollTop - element.clientHeight < 100;
      if (isNearBottom) {
        element.scrollTop = element.scrollHeight;
      }
    }
  };

  useEffect(scrollToBottom, [messages]);

  const appendOrUpdateAssistantMessage = (content: string) => {
    const svgStartPattern = /(?:<svg[^>]*>|```svg\s*<svg[^>]*>)/i;
    const svgEndPattern = /(?:<\/svg>|<\/svg>\s*```)/i;
  
    if (svgStateRef.current.isCollecting) {
      svgStateRef.current.buffer += content;
      if (svgEndPattern.test(svgStateRef.current.buffer)) {
        let svgContent = svgStateRef.current.buffer;
        svgContent = svgContent.replace(/```svg\s*/, '').replace(/\s*```$/, '');
        
        svgStateRef.current = { isCollecting: false, buffer: '' };
        
        setMessages(prev => {
          const svgContainer = `<div class="svg-container">${svgContent}</div>`;
          const lastMessage = prev[prev.length - 1];
          if (lastMessage?.type === 'assistant' && isAssistantRespondingRef.current) {
            return prev.map((m, index) => 
              index === prev.length - 1
                ? { ...m, content: m.content + svgContainer }
                : m
            );
          }
          else {
            isAssistantRespondingRef.current = true;
            return [...prev, {
              id: Date.now().toString(),
              type: 'assistant',
              content: svgContainer,
              timestamp: new Date()
            }];
          }
        });
      }
      return;
    }
  
    if (svgStartPattern.test(content)) {
      const svgStart = content.replace(/^```svg\s*/, '');
      svgStateRef.current = { isCollecting: true, buffer: svgStart };
      return;
    }
  
    setMessages(prev => {
      const lastMessage = prev[prev.length - 1];
      if (lastMessage?.type === 'assistant' && isAssistantRespondingRef.current) {
        return prev.map((m, index) => 
          index === prev.length - 1
            ? { ...m, content: m.content + content }
            : m
        );
      } else {
        isAssistantRespondingRef.current = true;
        return [...prev, {
          id: Date.now().toString(),
          type: 'assistant',
          content: content,
          timestamp: new Date()
        }];
      }
    });
  };

  const handleResponseChunk = (data: any) => {
    switch (data.type) {
      case 'token':
        appendOrUpdateAssistantMessage(data.content);
        break;
      case 'complete':
        setIsReceivingResponse(false);
        isAssistantRespondingRef.current = false;
        const summary = {
          provider: data.content.provider,
          model: data.content.model,
          usage: data.content.usage
        };
        setResponseSummary(summary);
        
        setMessages(prev => prev.map((m, index) => 
          index === prev.length - 1
            ? { ...m, summary }
            : m
        ));
        break;
      case 'error':
        setErrorMessage(data.content);
        setIsReceivingResponse(false);
        isAssistantRespondingRef.current = false;
        setResponseSummary(null);
        break;
    }
  };

  const handleSendMessage = useCallback(async (externalQuery?: string) => {
    const messageToSend = externalQuery || currentMessage;
    if (!messageToSend.trim() || isReceivingResponse) return;
  
    const newMessage = {
      id: Date.now().toString(),
      type: 'user' as const,
      content: messageToSend,
      timestamp: new Date()
    };
    
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    
    setCurrentMessage('');
    setErrorMessage('');
    setIsReceivingResponse(true);
    isAssistantRespondingRef.current = false;
    processedTextLengthRef.current = 0;
    
    const controller = new AbortController();
    abortControllerRef.current = controller;

    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig('stream');
      const api = AuthorEnrichmentApiFactory(apiConfig);
      
      const request: LLMRequest = {
        provider,
        model,
        messages: updatedMessages.map(m => ({
          role: m.type === 'user' ? 'user' : 'assistant',
          content: m.content
        })),
      };
    
      const config: RawAxiosRequestConfig = {
        ...apiParams,
        signal: controller.signal,
        onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
          const responseData = progressEvent.event.target as XMLHttpRequest;
          if (responseData.responseText) {
            const newText = responseData.responseText.slice(processedTextLengthRef.current);
            processedTextLengthRef.current = responseData.responseText.length;

            const lines = newText.split('\n');
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                try {
                  const data = JSON.parse(line.slice(5));
                  handleResponseChunk(data);
                } catch (e) {
                  if (line.trim() !== '') {
                    console.error('Failed to parse SSE data:', e);
                  }
                }
              }
            }
          }
        }
      };

      await api.generateTextStream(request, config);
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : 'Failed to send message');
      setIsReceivingResponse(false);
    }
  }, [provider, model, currentMessage, isReceivingResponse, messages]);

  const runQuery = useCallback(async (query: string) => {
    await handleSendMessage(query);
  }, [handleSendMessage]);

  useEffect(() => {
    if (ref) {
      (ref as React.MutableRefObject<LLMChatWindowMethods>).current = {
        runQuery
      };
    }
  }, [ref, runQuery]);
  
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
    setIsReceivingResponse(false);
  };

  return (
    <div className="chat-window-container">
      <div ref={chatWindowRef} className="chat-messages">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message ${message.type === 'user' ? 'message-user' : 'message-assistant'}`}
          >
            <div className="message-content">
              {message.type === 'user' ? (
                <div className="message-text">{message.content}</div>
              ) : (
                <div className="message-text" dangerouslySetInnerHTML={{ __html: message.content }} />
              )}
              {message.summary && (
                <div className="message-summary">
                  <div className="summary-provider">Via: {message.summary.provider} / {message.summary.model}</div>
                  <div className="summary-tokens">
                    Tokens: {message.summary.usage.totalTokens.toLocaleString()} 
                    ({message.summary.usage.promptTokens.toLocaleString()} in, 
                    {message.summary.usage.completionTokens.toLocaleString()} out)
                  </div>
                </div>
              )}
              <div className="message-timestamp">
                {message.timestamp.toLocaleTimeString()}
              </div>
            </div>
          </div>
        ))}
        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
      </div>
      <div className="chat-input-container">
        <div className="chat-input-wrapper">
          <textarea
            ref={textareaRef}
            className="chat-textarea"
            placeholder={isReceivingResponse ? "Waiting for response..." : "Type your custom query to test..."}
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            disabled={isReceivingResponse}
          />
          <button
            className={`chat-send-button ${isReceivingResponse ? 'cancel' : ''}`}
            onClick={isReceivingResponse ? handleCancel : () => handleSendMessage()}
            disabled={(!currentMessage.trim() && !isReceivingResponse)}
          >
            {isReceivingResponse ? 'Cancel' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
});

export default LLMChatWindow;