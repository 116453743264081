import React, { useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import './VariableAutocomplete.css';

interface VariableAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  selectedPdfText?: string;
  placeholder?: string;
  rows: number;
}

interface DropdownPosition {
  top: number;
  left: number;
}

interface CustomizationOption {
  id: string;
  label: string;
  value?: string;
}

const CustomizationList: CustomizationOption[] = [
  { id: 'user_location', label: 'User location', value: '{location}' },
  { id: 'user_age', label: 'User age', value: '{age}' },
  { id: 'user_device', label: 'User mobile device', value: '{device}' }
];

const VariableAutocomplete: React.FC<VariableAutocompleteProps> = ({ 
  value, 
  onChange,
  selectedPdfText = '',
  placeholder,
  rows
}) => {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>({ top: 0, left: 0 });
  const [filteredVariables, setFilteredVariables] = useState<string[]>([]);
  const [currentVar, setCurrentVar] = useState<string>('');
  const variables = CustomizationList.map(opt => opt.value?.slice(1, -1) ?? '');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const isMac = (): boolean => {
    if ('userAgentData' in navigator) {
      return (navigator as any).userAgentData?.platform === 'macOS';
    }
    return /Mac|iPhone|iPod|iPad/.test(navigator.userAgent);
  };

  const getCursorCoordinates = (): DropdownPosition | null => {
    const textarea = textareaRef.current;
    if (!textarea) return null;

    // Get cursor position in text
    const cursorPosition = textarea.selectionEnd;
    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    
    // Create a shadow div to measure text
    const div = document.createElement('div');
    // Copy the textarea's essential styles
    const style = window.getComputedStyle(textarea);
    div.style.cssText = `
      position: absolute;
      visibility: hidden;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font: ${style.font};
      letter-spacing: ${style.letterSpacing};
      padding: ${style.padding};
      width: ${textarea.offsetWidth}px;
      line-height: ${style.lineHeight};
    `;

    // Replace spaces with a visible character to preserve spacing
    const textContent = textBeforeCursor.replace(/ /g, '\u00a0');
    div.textContent = textContent;
    document.body.appendChild(div);

    // Find cursor position
    const cursorSpan = document.createElement('span');
    cursorSpan.textContent = '';
    div.appendChild(cursorSpan);

    // Get positions
    const textareaRect = textarea.getBoundingClientRect();
    const spanRect = cursorSpan.getBoundingClientRect();
    const divRect = div.getBoundingClientRect();

    // Clean up
    document.body.removeChild(div);

    // Calculate relative position within textarea
    const relativeLeft = spanRect.left - divRect.left;
    const relativeTop = spanRect.top - divRect.top;

    // Get line height in pixels
    const lineHeight = parseInt(style.lineHeight) || 20;

    // Account for scroll position and add half a line height
    const top = relativeTop - textarea.scrollTop + (lineHeight / 2);
    const left = relativeLeft - textarea.scrollLeft;

    return {
      top: Math.min(top + parseInt(style.lineHeight), textarea.offsetHeight - 10),
      left: Math.min(left, textarea.offsetWidth - 150) // 150 is approximate dropdown width
    };
  };

  const calculateDropdownPosition = (): void => {
    const coordinates = getCursorCoordinates();
    if (!coordinates) return;
    setDropdownPosition(coordinates);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    // Handle paste shortcut for selected PDF text
    if ((isMac() ? e.metaKey : e.ctrlKey) && e.shiftKey && (e.key === 'V' || e.key === 'v')) {
      e.preventDefault();
      insertSelectedPdfText();
      return;
    }
    if (e.key === 'Enter' && rows === 1) {
      e.preventDefault();
      return;
    }
  
    if (!dropdownVisible) return;

    // Handle dropdown navigation and selection
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const currentIndex = filteredVariables.findIndex(v => v === currentVar);
      if (e.key === 'ArrowDown') {
        const nextIndex = currentIndex < filteredVariables.length - 1 ? currentIndex + 1 : 0;
        setCurrentVar(filteredVariables[nextIndex]);
      } else {
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : filteredVariables.length - 1;
        setCurrentVar(filteredVariables[prevIndex]);
      }
    }
    else if (e.key === 'Enter' && currentVar) {
      e.preventDefault();
      insertVariable(currentVar);
    }
    else if (e.key === '}' ||
      e.key === 'Escape' ||
      e.key === 'Tab' ||
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight') {
      setDropdownVisible(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    // taking first line only in case of a single row
    let newValue = e.target.value;
    if (rows === 1) {
      newValue = e.target.value.split(/[\n\r]/)[0];
    }
    onChange(newValue);

    const cursorPosition = e.target.selectionEnd;
    const lastChar = newValue[cursorPosition - 1];
    
    // Show dropdown when typing '{'
    if (lastChar === '{') {
      setDropdownVisible(true);
      setFilteredVariables(variables);
      setCurrentVar(variables[0]);
      calculateDropdownPosition();
    }
    else if (dropdownVisible) {
      // Filter variables based on input after '{'
      const match = newValue.slice(0, cursorPosition).match(/(?<!\\){([^}]*)$/);
      if (match) {
        const searchTerm = match[1].toLowerCase();
        const filtered = variables.filter(v => 
          v.toLowerCase().includes(searchTerm)
        );
        setFilteredVariables(filtered);
        setCurrentVar(filtered[0]);
        calculateDropdownPosition();
      }
      else {
        setDropdownVisible(false);
      }
    }
  };

  const insertQueryText = (insertedText: string): void => {
    if (!textareaRef.current) return;

    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionEnd;
    const textBeforeCursor = value.substring(0, cursorPosition);
    const textAfterCursor = value.substring(cursorPosition);
    
    const newText = textBeforeCursor + insertedText + textAfterCursor;
    
    onChange(newText);
    setDropdownVisible(false);
    
    // Set cursor position after the inserted variable
    setTimeout(() => {
      const newPosition = cursorPosition + insertedText.length;
      textarea.focus();
      textarea.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  const insertSelectedPdfText = () => {
    if (selectedPdfText) {
      insertQueryText(selectedPdfText);
    }
  };

  const insertVariable = (variable: string): void => {
    insertQueryText(`${variable}}`);
  };

  useEffect(() => {
    // Handle clicks outside the component
    const handleClickOutside = (event: MouseEvent): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
          textareaRef.current && !textareaRef.current.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="variable-autocomplete-container">
      <textarea
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
        placeholder={placeholder}
        className={`variable-autocomplete-textarea ${rows === 1 ? 'variable-autocomplete-textarea-singlerow' : ''}`}
        rows={rows}
      />
      {dropdownVisible && filteredVariables.length > 0 && (
        <div
          ref={dropdownRef}
          className="variable-dropdown"
          style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
          }}
        >
          {filteredVariables.map((variable) => (
            <div
              key={variable}
              className={`variable-dropdown-item ${
                variable === currentVar ? 'selected' : ''
              }`}
              onClick={() => insertVariable(variable)}
            >
              {variable}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VariableAutocomplete;