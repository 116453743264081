import React, { useState, useEffect } from 'react';
import { LogOut } from 'lucide-react';
import { getFrontendVersionString } from './version';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import { auth } from './firebaseConfig';
import FirebaseAuth from './osakaFirebaseAuth';
import './Author.css';
import { apiService } from './ApiService';
import MainContent from './MainContent';

// Import the API function from the OpenAPI-generated code
import { AuthorBookApiFactory, BookDetails, Configuration, DefaultApiFactory, PartialBookDetails } from './osakaserver_api';
import { ui } from './firebaseUiConfig';

const Author: React.FC = () => {
  const [accessKey, setAccessKey] = useState<string>('');
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [serverVersion, setServerVersion] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [books, setBooks] = useState<BookDetails[]>([]);
  const [booksLoading, setBooksLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showBookInfoModal, setShowBookInfoModal] = useState<boolean>(false);
  const [showBookDeleteModal, setShowBookDeleteModal] = useState<boolean>(false);
  const [modifyBook, setModifyBook] = useState<BookDetails | null>(null);
  const [currentBook, setCurrentBook] = useState<BookDetails | null>(null);

  const handleAccessKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      checkAccessKey();
    }
  };

  const checkAccessKey = async () => {
    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = DefaultApiFactory(apiConfig);
      const accessKeyData = { key: accessKey };
      const response = await api.checkAccess(accessKeyData, apiParams);
      if (response.data) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
        alert('Invalid access key');
      }
    } catch (err) {
      console.error('Failed to check access key:', err);
      alert('Failed to check access key');
    }
  };

  const handleRefreshClick = async () => {
    setServerVersion(await apiService.getServerVersion());
    await fetchBooks(null);
  };

  // Get server version on mount
  useEffect(() => {
    apiService.getServerVersion().then((version) => {
      setServerVersion(version);
    });
  }, []);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        currentUser.getIdToken().then((idToken) => apiService.setFirebaseToken(idToken));
        setProfilePicture(currentUser.photoURL);
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch books when the user is authenticated
  useEffect(() => {
    fetchBooks(null);
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setCurrentBook(null);
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleAddBook = () => {
    setModifyBook({
      title: '',
      authors: '',
      description: '',
      type: 'paper',
    });
    setShowBookInfoModal(true);
  };

  const handleSaveBook = async () => {
    if (!modifyBook) {
      console.error('No book data found');
      return;
    }

    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorBookApiFactory(apiConfig);
    
    if (!modifyBook.id) {
      const bookId = await api.createBook(modifyBook, apiParams);
      if (bookId.data) {
        modifyBook.id = bookId.data;
      }
    } else {
      await api.updateBook(modifyBook.id, modifyBook, apiParams);
    }

    await fetchBooks(modifyBook);
    setShowBookInfoModal(false);
    setModifyBook(null);
  };

  const handleCancelBook = () => {
    setShowBookInfoModal(false);
    setModifyBook(null);
  };

  const handleBookClick = async (book: BookDetails) => {
    if (book.id && book.id !== currentBook?.id) {
      setCurrentBook(book);
    } else {
      setCurrentBook(null);
    }
  };

  const handleBookEditClick = () => {
    if (!currentBook || !currentBook.id) {
      console.error('No book ID found');
      return;
    }
    setModifyBook({ ...currentBook });
    setShowBookInfoModal(true);
  };

  const handleBookDeleteClick = async () => {
    if (!currentBook || !currentBook.id) {
      console.error('No book ID found');
      return;
    }
    setShowBookDeleteModal(true);
  };

  const handleDeleteBookConfirm = async () => {
    if (!currentBook || !currentBook.id) {
      console.error('No book ID found');
      return;
    }

    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorBookApiFactory(apiConfig);
    await api.deleteBook(currentBook.id, apiParams);

    await fetchBooks(null);
    setShowBookDeleteModal(false);
    setCurrentBook(null);
  };

  const handleDeleteBookCancel = () => {
    setShowBookDeleteModal(false);
  };

  const fetchBooks = async (newCurrentBook: BookDetails | null) => {
    if (user) {
      try {
        setBooksLoading(true);
        setError(null);

        const { apiConfig, apiParams } = await apiService.getApiConfig();
        const api = AuthorBookApiFactory(apiConfig);
        const response = await api.getAllBooks(apiParams);
        
        const transformedBooks: BookDetails[] = response.data.map((book: PartialBookDetails) => ({
          id: book.id ?? '',
          title: book.title ?? '',
          authors: book.authors ?? '',
          description: book.description ?? '',
          type: book.type ?? 'paper',
        }));
        
        setBooks(transformedBooks);
        
        if (newCurrentBook) {
          setCurrentBook({ ...newCurrentBook });
        } else {
          setCurrentBook(null);
        }
      } catch (err) {
        setError('Failed to fetch books.');
      } finally {
        setBooksLoading(false);
      }
    }
  };

  if (!hasAccess) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <h2>Restricted Access</h2>
        <input
          type="password"
          placeholder="Enter Access Key"
          value={accessKey}
          onChange={(e) => setAccessKey(e.target.value)}
          onKeyDown={handleAccessKeyPress}
        />
        <button onClick={checkAccessKey}>Submit</button>
      </div>
    );
  }

  return (
    <div className="app-container">
      {/* Drawer-style Sidebar */}
      <div className="drawer">
        <div className="version-info">
          <h5 className="project-server-version">BE: {serverVersion}</h5>
          <h5 className="project-frontend-version">FE: {getFrontendVersionString()}</h5>
          <button className="refresh-button" onClick={handleRefreshClick}>
            &#x21bb;
          </button>
        </div>
        <div className="logo-section">
          <img src="/logo.webp" alt="Enriched Books Logo" className="project-logo" />
          <h2 className="project-name">Enriched Books</h2>
        </div>

        {/* Account Section */}
        <div className="account-section section">
          {user ? (
            <>
              <button className="sign-out-button" onClick={handleSignOut} aria-label="Sign out">
                <LogOut size={20} />
              </button>
              {profilePicture ? (
                <img src={profilePicture} alt="Profile" className="profile-picture" />
              ) : (
                <div className="default-avatar">
                  {user.displayName
                    ? user.displayName.split(' ').splice(0, 2).map(name => name.charAt(0)).join('')
                    : 'A'}
                </div>
              )}
              <div className="account-name">{user.displayName}</div>
              <div className="account-email">{user.email}</div>
            </>
          ) : (
            <>
              <p>Sign in to your account:</p>
              <FirebaseAuth />
            </>
          )}
        </div>

        {/* Section for Book List */}
        {user && (
          <div className="books-section section">
            <div className="books-header">
              <h3 className="books-title">Your Books</h3>
              <button className="add-book-button" onClick={handleAddBook}>+</button>
            </div>
            {booksLoading ? (
              <p>Loading books...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <ul className="books-list">
                {books.map((book) => (
                  <li
                    key={book.id}
                    className={`book-item ${currentBook && currentBook.id === book.id ? 'current' : ''}`}
                    onClick={() => handleBookClick(book)}
                  >
                    <div className="book-title">{book.title}</div>
                    <div className="book-authors">{book.authors}</div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>

      {/* Main Content Area */}
      <MainContent
        currentBook={currentBook}
        user={user}
        onBookUpdated={async () => {
          await fetchBooks(null);
        }}
        onBookEdit={(book) => {
          setModifyBook({ ...book });
          setShowBookInfoModal(true);
        }}
        onBookDelete={(book) => {
          setShowBookDeleteModal(true);
        }}
      />

      {/* Modal for Adding/Editing Book */}
      {showBookInfoModal && modifyBook && (
        <div className="edit-book-modal-overlay">
          <div className="edit-book-modal">
            <h3>Edit Book Details</h3>
            <input
              type="text"
              placeholder="Book Title"
              value={modifyBook.title}
              onChange={(e) => setModifyBook({ ...modifyBook, title: e.target.value })}
            />
            <input
              type="text"
              placeholder="Book Authors"
              value={modifyBook.authors}
              onChange={(e) => setModifyBook({ ...modifyBook, authors: e.target.value })}
            />
            <select
              value={modifyBook.type}
              onChange={(e) => setModifyBook({ ...modifyBook, type: e.target.value })}
            >
              <option value="paper">Paper</option>
              <option value="digital">Digital</option>
            </select>
            <div className="edit-book-modal-buttons">
              <button onClick={handleSaveBook}>Save</button>
              <button onClick={handleCancelBook}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Deleting Book */}
      {showBookDeleteModal && (
        <div className="delete-book-modal-overlay">
          <div className="delete-book-modal">
            <h3>Delete book "{currentBook?.title}"?</h3>
            <div className="delete-book-modal-buttons">
              <button onClick={handleDeleteBookConfirm}>Delete</button>
              <button onClick={handleDeleteBookCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Author;